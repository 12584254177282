@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './styles/helpers.less';

@primary-color: #dc582a;
@btn-default-color: #dc582a;
@btn-default-border: #dc582a;
@secondary-color: #5B356D;

@font-face {
  font-family: 'MarkPro';
  src: url('./fonts/MarkPro.woff2') format('woff2'),
      url('./fonts/MarkPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarkProBold';
  src: url('./fonts/MarkPro-Bold.woff2') format('woff2'),
      url('./fonts/MarkPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarkPro-Book';
  src: url('./fonts/MarkPro-Book.woff2') format('woff2'),
      url('./fonts/MarkPro-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MarkProBlack';
  src: url('./fonts/MarkPro-Black.woff2') format('woff2'),
      url('./fonts/MarkPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'MarkPro'; font-weight: normal; font-style: normal;
}

h1 {
  font-size: 20px;
  font-family: 'MarkProBold';
  color: @primary-color;

}

body, div, html, p, span {
  color: #505050;
}

tr th {
  color: #505050 !important;
}

strong {
  font-weight: bold;
}

.ant-btn-default {
  span {
    color: @primary-color;
  }
}

.ant-btn.ant-btn-primary {
  span {
    color: white;
  }
}

.ant-btn-primary[disabled] {
  background-color: lighten(@primary-color, 30);
  border-color: lighten(@primary-color, 30);
  &:hover {
    background-color: lighten(@primary-color, 30);
    border-color: lighten(@primary-color, 30);
  }
}

.ant-layout {
  background-color: white;
}

.ant-layout-header {
  background-color: white;
  box-shadow: 1px 1px 34px -17px black;
  height: 66px !important;
}

.ant-page-header-heading-title {
  font-family: 'MarkPro';
  font-weight: 900;
  font-style: normal;
  font-size: 2.2em;
  color: @primary-color;
}

.month-selector {
  font-family: 'MarkProBold';
  font-size: 30px;
  color: @primary-color;
  text-align: center;
  .selection {
    font-family: 'MarkPro-Book';
    font-size: 16px;
  }
}

.detail-card {
  background-color: #F1F1F1;
  text-align: center;
  padding: 20px;
  .quantity {
    font-size: 30px;
  }
  .state {
    font-weight: bold;
  }
  &.not_processing {
    p, div, span {
      color: #505050 !important;
    }
  }
  &.waiting_oc, &.waiting_authorization {
    p, div, span {
      color: #DC582A !important;
    }
  }
  &.invoiced, &.ready_to_bill {
    p, div, span {
      color: #2F9980 !important;
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab:nth-child(0) {
      &.ant-tabs-tab-active {
        border-bottom: 2px solid black;
      }
    }
  }
}

.open-month-action {
  height: 300px;
  text-align: center;
  div {
    font-size: 20px;
  }
  margin-top: 40px;
  .loading {
    font-size: 16px;
    .ant-spin {
      font-size: 20px;
    }
    span {
      display: block;
      font-size: 14px;
    }
  }
}

.prestations {
  margin-top: 40px;
  .prestation-table {
    font-size: 0.9em;
    width: 100%;
  }
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
  color: #DC582A;
  font-family: 'MarkProBold'
}

table {
  thead {
    tr, th {
      background-color: white !important;
    }
  }
  tr {
    background-color: #F1F1F1;
    td {
      border-bottom: 10px solid white !important;
    }
    text-transform: capitalize;
  }
}

.ant-radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .ant-radio-wrapper{
    width: 50%;
    margin: 0;
    margin-bottom: 20px;
  }
}

.invoice-generator-header {
  h1 {
    line-height: 30px;
    span {
      font-size: 20px;
      color: @primary-color;
    }
  }
}